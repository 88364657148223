import { render, staticRenderFns } from "./Handler.vue?vue&type=template&id=d73de2f6&"
import script from "./Handler.vue?vue&type=script&lang=ts&"
export * from "./Handler.vue?vue&type=script&lang=ts&"
import style0 from "./Handler.vue?vue&type=style&index=0&id=d73de2f6&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CtaExtAndApp: require('/home/cleavr/www.jdescuentos.cl/releases/20240911124229529/components/cta/ExtAndApp.vue').default,CtaEmail: require('/home/cleavr/www.jdescuentos.cl/releases/20240911124229529/components/cta/Email.vue').default,CtaTelegram: require('/home/cleavr/www.jdescuentos.cl/releases/20240911124229529/components/cta/telegram.vue').default,CtaFacebook: require('/home/cleavr/www.jdescuentos.cl/releases/20240911124229529/components/cta/facebook.vue').default,CtaWhatsapp: require('/home/cleavr/www.jdescuentos.cl/releases/20240911124229529/components/cta/whatsapp.vue').default})
